import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Dynamicform from "../../dynamicform";
import { addDataFormatForm } from "./adddataformatform.config";

import { addDataFormatSchema } from "../../../../utils/schemas";
import {
  AddDataFormatFormType,
  AddValueRangeFormProps,
} from "./adddataformatform.types";

import { GetAddRuleFormContentProps } from "../../addruleform.types";
import { dataFormatFormDataToApiData } from "./adddataformatformutil";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

import { SelectFieldOption } from "../../../../components/formfields/selectfield/selectfield.types";
import { GetPatternsParsedDataType } from "../../../../parsers/ruleparser/ruleparser.types";

import { PatternOptionStyled } from "./adddataformatform.styles";
import { editPencilIcon } from "../../../../svgs";

import LinkButton from "../../../../components/linkbutton/linkbutton";
import { AddPatternModal, ColumnInfoContent } from "./adddataformatform.comp";

import { useOpenDrawer } from "../../../../customhooks";
import { SectionField } from "../../dynamicform/dynamicform.types";

const editPencil = editPencilIcon("11", "11");

const AddDataFormatForm = (
  props: GetAddRuleFormContentProps & AddValueRangeFormProps
): JSX.Element => {
  const {
    columnSelectOptions,
    isFromRuleSet = false,
    selectedColumnId,
    propsOnCancel,
    propsOnSubmit,
    sourceId,
    tableId,
    onAddRule,
    columnsData,
    formId,
    ruleCategoryId,
    ruleConfig,
    isEdit = false,
    onUpdateRule,
    patternList,
  } = props;

  const [isPatternModalVisible, setIsPatternModalVisible] = useState(false);
  const [isTooltipHidden, setIsTooltipHidden] = useState(false);
  const openDrawer = useOpenDrawer();

  const [
    patternToEdit,
    setPatternToEdit,
  ] = useState<GetPatternsParsedDataType>();

  const { rule_desc: desc = "", col_id: colId, pattern } = ruleConfig || {};

  const initialFilters = ruleConfig?.filter_cond;

  const form = useForm<AddDataFormatFormType>({
    defaultValues: {
      id: "data_format",
      rule_type: "Data Format",
      rule_description: desc,
      column: isFromRuleSet ? selectedColumnId : colId ? String(colId) : "",
      pattern,
      is_filter_criteria_active: isEdit,
      filter_criteria: [],
    },
    resolver: yupResolver(addDataFormatSchema),
    mode: "onChange",
  });

  const {
    watch,
    setValue,
    formState: { errors },
  } = form;
  const { pattern: patternName } = watch();

  const onPatterEditClick = useCallback((e, pattern) => {
    e?.stopPropagation();
    setIsPatternModalVisible(true);
    setPatternToEdit(pattern);
  }, []);

  const parsedSelectItem = (
    item: GetPatternsParsedDataType
  ): SelectFieldOption => {
    return {
      label: (
        <PatternOptionStyled>
          <div className="name-desc-wrapper">
            <span className="pattern-name">{item?.name}</span>
            {item?.description && (
              <span className="pattern-desc" title={item?.description}>
                ({item?.description})
              </span>
            )}
          </div>

          {!item?.refId && (
            <LinkButton
              className="pattern-edit-icon"
              onClick={(e): void => onPatterEditClick(e, item)}
            >
              {editPencil}
            </LinkButton>
          )}
        </PatternOptionStyled>
      ),
      labelText: item?.name,
      value: item?.refId ? item?.refId : String(item?.refSeqId),
      labelDesc: `${item?.description} \n ${item?.regex} `,
      key: item?.regex,
    };
  };

  const patternSelectOptionss = useMemo(() => {
    return [
      {
        label: "Default",
        options:
          patternList?.default?.map((item) => parsedSelectItem(item)) || [],
      },
      {
        label: "Custom",
        options:
          patternList?.custom?.map((item) => parsedSelectItem(item)) || [],
      },
    ];
  }, [patternList]);

  const defaultPatterns = patternList?.default || [];
  const customPatterns = patternList?.custom || [];
  const allPaternsList = [...defaultPatterns, ...customPatterns];

  const selectedPattern = allPaternsList?.find(
    (item) =>
      Number(item?.refId) === Number(patternName) ||
      Number(item?.refSeqId) === Number(patternName) ||
      String(item?.refId) === String(patternName) ||
      String(item?.refSeqId) === String(patternName)
  );

  const selectedPatternDescAndRegex = selectedPattern
    ? `${selectedPattern?.description} ${selectedPattern?.regex}`
    : "";

  const columnSelectOptionsTooltip: SelectFieldOption[] = columnsData
    // ?.filter((item) => item?.field_datatype === "STR") //Suggested by ramana to remove this check
    ?.map((col) => {
      return {
        label: (
          <PatternOptionStyled>{col?.field_display_name}</PatternOptionStyled>
        ),
        value: String(col?.field_id),
        textForSearch: col?.field_display_name,
        labelDesc: <ColumnInfoContent col={col} />,
        type: col?.field_datatype,
      };
    });

  const filteredFormType = addDataFormatForm({
    isFromRuleSet,
    columnSelectOptions: columnSelectOptionsTooltip,
    existingFilters: initialFilters,
    patternSelectOptions: patternSelectOptionss,
    selectedOptionDesc: selectedPatternDescAndRegex,
    onCreateNewPattern: (): void => setIsPatternModalVisible(true),
    isEdit,
  })?.filter(
    (section: SectionField) =>
      // section?.id?.includes(watchDataType?.toLowerCase()) ||
      section?.id === "basic" || section?.id === "basic_input"
  );

  const onSubmit = useCallback(
    (values: AddDataFormatFormType) => {
      const rule_config = dataFormatFormDataToApiData(values);

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const selectedColumnId = Number(values?.column);

      const desc = values?.rule_description;
      const regex = selectedPattern?.regex || "";

      const ruleConfig = {
        ...rule_config,
        rule_type: formId,
        rule_desc: desc,
        regex,
      };

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: tableId,
        rule_priority: "NRM",
        rule_def_config: ruleConfig,
        col_ids: [selectedColumnId],
        filter: filterCondition,
      };

      const modifyRulePayload = {
        ...ruleConfig,
        filter_cond: filterCondition,
        col_ids: [selectedColumnId],
      };

      isFromRuleSet
        ? propsOnSubmit?.({ ...values, regex })
        : isEdit
        ? onUpdateRule?.(modifyRulePayload)
        : onAddRule(addNewRulePayload);
    },
    [
      isFromRuleSet,
      tableId,
      sourceId,
      onAddRule,
      columnsData,
      selectedPattern,
      patternSelectOptionss,
    ]
  );

  const onHandleCancelPatternModal = useCallback(() => {
    setIsPatternModalVisible(false);
    setPatternToEdit(undefined);
  }, []);

  const onEditPatternSuccess = useCallback(() => {
    setIsPatternModalVisible(false);
    setPatternToEdit(undefined);
  }, []);

  const onAddPatternSuccess = useCallback((newlyAddedPattern: string) => {
    setValue("pattern", newlyAddedPattern, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsPatternModalVisible(false);
    setPatternToEdit(undefined);
  }, []);

  const onDeleteSuccess = useCallback(() => {
    // first of all find item that is selected in pattern select field
    const findSelectedPattern = patternList?.custom?.find(
      (item) =>
        Number(item?.refId) === Number(patternName) ||
        Number(item?.refSeqId) === Number(patternName)
    );

    // then check  the one u edit/delete is the one that is selected
    if (patternToEdit?.name === findSelectedPattern?.name)
      setValue("pattern", undefined);

    setIsPatternModalVisible(false);
    setPatternToEdit(undefined);
  }, [patternToEdit, patternName, patternList]);

  return (
    <FormProvider {...form}>
      <Dynamicform
        schema={filteredFormType}
        onSubmit={onSubmit}
        isFromRuleSet={isFromRuleSet}
        propsOnCancel={propsOnCancel}
        columnsData={columnsData}
        isEdit={isEdit}
      />

      {isPatternModalVisible && (
        <AddPatternModal
          isModalVisible={isPatternModalVisible}
          onHandleCancel={onHandleCancelPatternModal}
          propsOnAddSuccess={onAddPatternSuccess}
          isFromRuleSet={isFromRuleSet}
          patternConfig={patternToEdit}
          propsOnDeleteSuccess={onDeleteSuccess}
          propsOnEditSuccess={onEditPatternSuccess}
        />
      )}
    </FormProvider>
  );
};

export default AddDataFormatForm;
