// <--- Library Imports Start --->
import { Menu, Button, Layout } from "antd";

import { useDispatch } from "react-redux";
import { useMemo, useCallback, useState } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
// <--- Library Imports End --->

// <--- Constants Start --->
import { APP_ROUTES } from "../../constants";
import { LOCAL_STORAGE_CONSTANTS } from "../../constants/localstorageconstants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import { useGetAppState } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Reducers Start --->
import { changeSideBarPosition } from "../../reducers/appreducer/appreducer";
// <--- Reducers End --->

// <--- Styles Start --->
import { NavBarStyle, SiderStyled } from "./navbar.style";
// <--- Styles End --->

// <--- Types Start --->
import { RouteFace, NavbarFace, SideNavProps } from "./navbar.interfaces";
// <--- Types End --->

// <--- Utils Start --->
import { getUserPermissions } from "../../utils";

import { getPostLoginData } from "../../utils/getpostlogindata";
import { getSelectedMenuKey, getSideNavConfig } from "./navbar.utils";
import { getModuleLevelAccessInfo } from "../../utils/getModuleLevelAccessInfo";
import { getAdminAccessData } from "../../utils/getadminfinegrainaccesslist";
// <--- Utils End --->

const { SubMenu } = Menu;

const { Content } = Layout;

export function Navbar(props: NavbarFace): React.ReactElement {
  const { isCollapsed, changeCollapse, propSideNavConfig } = props;

  const { enabledObjects } = getAdminAccessData();
  const filteredObjects =
    enabledObjects?.filter((obj) => obj.access_type !== "NOA") || [];

  const { isOnboardingMode } = useGetAppState();

  const { pathname } = useLocation();
  const history = useHistory();

  const [openKeys, setOpenKeys] = useState<string[]>();

  const selectedMenuItemKey = useMemo(
    () =>
      getSelectedMenuKey(
        pathname
          .replace("/", "")
          .replace("-", "_") as keyof typeof APP_ROUTES.private_routes,
        isCollapsed
      ),
    [pathname, isCollapsed]
  );

  const { user_info: userInfo = {} } = getPostLoginData();

  const isOnlyViewer =
    userInfo?.admin_access_type === "NOA" &&
    userInfo?.catalog_access_type === "VWR" &&
    userInfo?.glossary_access_type === "VWR";

  const {
    hasCatalogAccess,
    hasGlossaryAccess,
    isChatEnabled,
    isDqEnabled,
  } = getModuleLevelAccessInfo();

  const {
    is_account_admin: isAccountAdmin = false,
    is_glossary_admin: isGlossaryAdmin = false,
    is_catalog_admin: isCatalogAdmin = false,
    is_catalog_editor: isCatalogEditor = false,
    is_glossary_editor: isGlossaryEditor = false,
    is_user_admin: isUserAdmin = false,
  } = getUserPermissions();

  const isAnyAdmin =
    isAccountAdmin || isCatalogAdmin || isGlossaryAdmin || isUserAdmin;

  const isAnyEditor = isCatalogEditor || isGlossaryEditor;
  const sideNavConfig = useMemo(
    () =>
      propSideNavConfig
        ? propSideNavConfig(isCollapsed)
        : getSideNavConfig(
            isCollapsed,
            isAnyAdmin,
            isAnyEditor,
            isGlossaryAdmin,
            isUserAdmin,
            isAccountAdmin,
            isCatalogAdmin,
            hasCatalogAccess,
            hasGlossaryAccess,
            isChatEnabled,
            isDqEnabled
          ),
    [
      isCollapsed,
      isOnlyViewer,
      isAnyEditor,
      isAnyAdmin,
      isGlossaryAdmin,
      isUserAdmin,
      isAccountAdmin,
      isCatalogAdmin,
      hasCatalogAccess,
      isChatEnabled,
      hasGlossaryAccess,
      isDqEnabled,
    ]
  );

  const subMenuKeys = useMemo(() => {
    return sideNavConfig
      ?.filter((item: RouteFace) => item?.subRoutes?.length || 0 > 1)
      ?.map((item: RouteFace) => item?.key);
  }, [sideNavConfig]);

  const onOpenChange = useCallback(
    (keys) => {
      const latestOpenKey = keys.find(
        (key: string) => openKeys && openKeys?.indexOf(key) === -1
      );

      if (subMenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [subMenuKeys]
  );

  return (
    <NavBarStyle administrationTabs={filteredObjects?.length}>
      <Menu
        selectedKeys={[selectedMenuItemKey]}
        mode="inline"
        theme="dark"
        className="menu-container"
        getPopupContainer={(trigger): HTMLElement =>
          trigger.parentNode as HTMLElement
        }
        inlineCollapsed={isCollapsed}
        onClick={({ key, keyPath }): void => {
          setOpenKeys(
            keyPath?.length > 1
              ? keyPath?.filter((menuKey) => menuKey !== key)
              : []
          );
        }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {sideNavConfig.map((item: RouteFace) => {
          return item?.subRoutes?.length ? (
            <SubMenu
              key={item.key}
              icon={item.icon()}
              title={item.title}
              className="sub-menu-container"
              onTitleClick={(): void => {
                item?.link &&
                  item?.subRoutes?.length === 1 &&
                  (item?.openInNewTab && !isOnboardingMode
                    ? window.open(item?.link, "_blank")
                    : history.push(item?.link || ""));
              }}
              //@ts-ignore
              id={item?.id || ""}
            >
              {item.subRoutes.map((sItem) => (
                <Menu.Item
                  key={sItem.key}
                  icon={sItem.icon ? sItem.icon() : ""}
                  className="submenu-item"
                  id={sItem?.id || ""}
                >
                  {sItem?.link ? (
                    <Link
                      target={item?.openInNewTab ? "_blank" : "_self"}
                      to={sItem.link}
                    >
                      {sItem.title}
                    </Link>
                  ) : (
                    <span>{sItem.title}</span>
                  )}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item?.icon ? item.icon() : ""}
              className="sub-menu-container"
              id={item?.id || ""}
            >
              {item?.link ? (
                <Link
                  target={
                    item?.openInNewTab && !isOnboardingMode ? "_blank" : "_self"
                  }
                  to={item.link}
                >
                  {item.title}
                </Link>
              ) : (
                <span>{item.title}</span>
              )}
            </Menu.Item>
          );
        })}
        <Layout className="side-handler-container">
          <Content className="btn-content-container">
            <Button
              shape="circle"
              icon={
                !isCollapsed ? (
                  <BsChevronDoubleLeft />
                ) : (
                  <BsChevronDoubleRight />
                )
              }
              onClick={(): void => changeCollapse(!isCollapsed)}
              size="small"
              className="snv-handler-btn"
            />
          </Content>
        </Layout>
      </Menu>
    </NavBarStyle>
  );
}

function SideNav({ propSideNavConfig }: SideNavProps): JSX.Element {
  const { sideBarPosition } = useGetAppState();
  const dispatch = useDispatch();

  const onChangeSidebarPosition = useCallback(() => {
    const position =
      sideBarPosition === "collapsed" ? "not-collapsed" : "collapsed";
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS?.sidebar_position, position);
    dispatch(changeSideBarPosition(position));
  }, [sideBarPosition]);

  const isCollapsed = useMemo(() => sideBarPosition === "collapsed", [
    sideBarPosition,
  ]);

  return (
    <div>
      <SiderStyled isCollapsed={isCollapsed} collapsed={isCollapsed}>
        <Navbar
          isCollapsed={isCollapsed}
          changeCollapse={onChangeSidebarPosition}
          propSideNavConfig={propSideNavConfig}
        />
      </SiderStyled>
    </div>
  );
}

export default SideNav;
