import { getParsedCurrentIPAddress, getParsedUserGroups } from "../parsers";
import {
  getNodeParentalInfoByNodeType,
  getDataTabProfilingInfo,
  getNodeTypeCATParser,
  getNodeTypeTAGContextParser,
  getOverviewData,
  getRelatedTables,
  getTableCountHistory,
  getUsersOrUserGroups,
  getParsedRelationshipColumnsData,
  getSearchedNodeTypeCat,
} from "../parsers/tablepage/tablepageparser";

import {
  getParsedUserRoles,
  getParsedUsers,
} from "../parsers/usergroups/usergroupsparser";

import { userGroupsTableSVG, userRolesTableSVG } from "../svgs";
import { getEnvVariables } from "../utils";
import { APIKeys } from "./constant.types";

import {
  getParsedAccessTypes,
  getParsedEnabledObjects,
  getParsedUserGroups as getParsedUserGroupsForRolesPage,
  getParsedUserRoles as getParsedUserRolesForRolesPage,
} from "../parsers/userroles/userrolesparser";

import { usersTableSVG } from "../blankslates";

import {
  getParsedDataSources,
  getParsedDataSourceTypes,
  getParsedExcelSourceFiles,
  getParsedExcelSourceJobStatus,
  getParsedSingleDataSource,
} from "../parsers/sources/sourcesparser";

import {
  sourcesTableBlankSlate,
  excelSourceFilesGridBlankSlate,
} from "../blankslates/sourcestableblankslate";

import { sourceScanHistoryTableBS } from "../blankslates/sourcescanhistorytableblankslate";
import { getNodeSuggestionsList } from "../parsers/nodesuggestions/nodesuggestionsparser";

import {
  getParsedMassDataClassificationFields,
  getParsedBotEngagements,
  getParsedBotTopUsers,
  getParsedMassEditableFields,
  getParsedNodeData,
} from "../parsers/listingpagesparser/listingpagesparser";

import { getRecentSearchKeyWords } from "../components/searchwithdropdown/searchwithdropdown.parser";

import {
  getAnalyticsDictionaryAnalytics,
  getAnalyticsStats,
  getDataDictionaryAnalytics,
  getGlossaryAnalytics,
  getSingleGlossaryAnalytics,
  getSingleSourceAnalytics,
  getSingleTagsetAnalytics,
  getTagsAnalytics,
  getUsersAnalytics,
  getSinglAnalyticsSourceAnalytics,
} from "../parsers/analyticsparser";

import {
  getTermChangesForReview,
  getUserActivities,
  getWorkflowSteps,
  getWorkflowTemplates,
} from "../parsers/termsparser/termsparser";

import {
  getParsedReviewCaddiFeedbacksList,
  parseFieldsOfPinnedTables,
} from "../parsers/analysisparser/analysisparser";
import { getParsedModuleSettings } from "../parsers/modulesettingparser/modulesettingparser";
import { getErdEdgeInfo } from "../parsers/erddiagramparser/erddiagramparser";

const { REACT_APP_API_DOMAIN } = getEnvVariables();

export type API_CONFIG_TYPE = {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  parser?: (_data: any) => void;
  blankSlate?: any;
};

export const API_CONFIG: {
  [key in APIKeys]: API_CONFIG_TYPE;
} = {
  load_from_dynamo: {
    method: "GET",
    url: `https://jbdjj8lz76.execute-api.us-west-2.amazonaws.com/dev/tables/52286151`,
  },
  load_from_elastic_search: {
    method: "GET",
    url: `https://r2uzdymocj.execute-api.us-west-2.amazonaws.com/dev/search?account_id=url_param&searchText=url_param`,
  },
  load_from_aurora: {
    method: "GET",
    url: `https://gr8mpes38l.execute-api.us-west-2.amazonaws.com/dev/tables?account_id=url_param`,
  },
  get_all_user_groups: {
    url: `${REACT_APP_API_DOMAIN}/admin/user-groups`,
    method: "GET",
    parser: getParsedUserGroups,
    blankSlate: userGroupsTableSVG,
  },
  add_user_group: {
    url: `${REACT_APP_API_DOMAIN}/admin/user-groups`,
    method: "POST",
  },
  edit_user_group: {
    url: `${REACT_APP_API_DOMAIN}/admin/user-groups/url_param`,
    method: "PUT",
  },
  delete_user_group: {
    url: `${REACT_APP_API_DOMAIN}/admin/user-groups/url_param`,
    method: "DELETE",
  },
  get_all_user_roles: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles`,
    method: "GET",
    parser: getParsedUserRoles,
  },
  get_all_users: {
    url: `${REACT_APP_API_DOMAIN}/admin/users`,
    method: "GET",
    parser: getParsedUsers,
    blankSlate: usersTableSVG,
  },

  get_node: {
    url: `${REACT_APP_API_DOMAIN}/nodes/details?node-id=url_param&node-type=url_param&node-status=url_param&isRef=url_param&analysis_type=url_param&q=url_param`,
    method: "GET",
    parser: getOverviewData,
  },
  update_node: {
    url: `${REACT_APP_API_DOMAIN}/nodes/details?node-id=url_param&node-type=url_param&node-status=url_param&isRef=url_param&analysis_type=url_param&q=url_param`,
    method: "PUT",
  },
  add_pattern: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/patterns`,
    method: "POST",
  },
  get_filter: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/filters?node-type=url_param&filter-type=url_param&filters-only=url_param`,
    method: "GET",
  },
  get_suggestions: {
    url: `${REACT_APP_API_DOMAIN}/nodes/suggestions?node-id=url_param`,
    method: "GET",
    parser: getNodeSuggestionsList,
  },
  create_suggestion: {
    url: `${REACT_APP_API_DOMAIN}/nodes/suggestions?node-id=url_param&node-type=url_param`,
    method: "POST",
  },
  resolved_suggestion: {
    url: `${REACT_APP_API_DOMAIN}/nodes/suggestions?suggestion-id=url_param`,
    method: "PATCH",
  },
  get_user_role: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles/url_param`,
    method: "GET",
  },
  add_user_role: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles`,
    method: "POST",
  },
  edit_user_role: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles/url_param`,
    method: "PUT",
  },
  delete_user_role: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles/url_param`,
    method: "DELETE",
  },
  get_all_user_roles_for_role_page: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles`,
    method: "GET",
    parser: getParsedUserRolesForRolesPage,
    blankSlate: userRolesTableSVG,
  },
  get_all_user_groups_for_role_page: {
    url: `${REACT_APP_API_DOMAIN}/admin/user-groups`,
    method: "GET",
    parser: getParsedUserGroupsForRolesPage,
  },
  get_all_access_types_for_role_page: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles/access-types`,
    method: "GET",
    parser: getParsedAccessTypes,
  },
  get_user_users_groups: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?is-editor-or-admin-type=url_param&node-type=url_param&search-text=url_param`,
    method: "GET",
    parser: getUsersOrUserGroups,
  },
  get_searched_entities: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=TRM&search-text=url_param&trm-type=url_param`,
    method: "GET",
    parser: getNodeTypeCATParser,
  },
  get_node_type_cat: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=url_param`,
    method: "GET",
    parser: getNodeTypeCATParser,
  },

  get_node_tags: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=TAG&node-context=url_param`,
    method: "GET",
    // parser: getNodeTypeTAGContextParser,
  },
  get_node_parental_info_by_node_type: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=url_param&node-context=url_param&search-text=url_param`,
    method: "GET",
    parser: getNodeParentalInfoByNodeType,
  },
  delete_user: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/url_param`,
    method: "DELETE",
  },
  deactivate_user: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/url_param`,
    method: "PATCH",
  },
  add_user: {
    url: `${REACT_APP_API_DOMAIN}/admin/users`,
    method: "POST",
  },
  edit_user: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/url_param`,
    method: "PUT",
  },
  get_all_data_sources: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources`,
    method: "GET",
    parser: getParsedDataSources,
    blankSlate: sourcesTableBlankSlate,
  },
  get_all_data_source_types: {
    url: `${REACT_APP_API_DOMAIN}/admin/source-types`,
    method: "GET",
    parser: getParsedDataSourceTypes,
  },
  add_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources`,
    method: "POST",
  },
  edit_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param`,
    method: "POST",
  },
  delete_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param`,
    method: "DELETE",
  },
  get_data_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param`,
    method: "GET",
    parser: getParsedSingleDataSource,
  },
  get_table_count_history: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/record-count-history`,
    method: "GET",
    parser: getTableCountHistory,
  },
  get_basic_profiling_info: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/profiling?resource=url_param`,
    method: "GET",
    parser: getDataTabProfilingInfo,
  },
  get_table_columns: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?resource=url_param&is_detailed=url_param`,
    method: "GET",
  },
  get_related_tables: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/related-tables`,
    method: "GET",
    parser: getRelatedTables,
  },
  get_current_ip_address: {
    url: "https://geolocation-db.com/json/",
    method: "GET",
    parser: getParsedCurrentIPAddress,
  },
  get_all_refs_type: {
    url: `${REACT_APP_API_DOMAIN}/admin/refs?ref_type=url_param`,
    method: "GET",
  },
  get_source_scan_history: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/scan-history?page-number=url_param&records-per-page=url_param&sort=url_param`,
    method: "GET",
    blankSlate: sourceScanHistoryTableBS,
  },
  get_search_node: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=url_param&search-text=url_param&search-by-parent=url_param`,
    method: "GET",
  },
  get_pattern: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/patterns`,
    method: "GET",
  },
  update_source_general_settings: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/settings/general`,
    method: "PUT",
  },
  get_subscriptions: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/subscription`,
    method: "GET",
  },
  get_api_key: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/api-key`,
    method: "GET",
  },
  regenerate_api_key: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/api-key`,
    method: "PUT",
  },
  get_ip_addresses: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/ip-address`,
    method: "GET",
  },
  add_ip_address: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/ip-address`,
    method: "POST",
  },
  delete_ip_address: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/ip-address`,
    method: "DELETE",
  },
  get_domains: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/user-security/domain`,
    method: "GET",
  },
  add_domain: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/user-security/domain`,
    method: "POST",
  },
  delete_domain: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/user-security/domain`,
    method: "DELETE",
  },
  update_source_connection_settings: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/settings/connection`,
    method: "PUT",
  },
  get_all_saws: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws`,
    method: "GET",
  },
  add_saws: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws`,
    method: "POST",
  },
  edit_saws: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/url_param`,
    method: "PUT",
  },
  delete_saws: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/url_param`,
    method: "DELETE",
  },
  edit_column_config: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?isRef=url_param`,
    method: "PUT",
  },

  get_all_sso: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso/idp`,
    method: "GET",
  },
  add_sso: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso/idp`,
    method: "POST",
  },
  edit_sso: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso/idp/url_param`,
    method: "PUT",
  },
  delete_sso: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso/idp/url_param`,
    method: "DELETE",
  },
  get_sso_settings: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso`,
    method: "GET",
  },
  run_scan: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/run-scan`,
    method: "POST",
  },
  set_sso_settings: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso`,
    method: "PATCH",
  },
  get_firewall_enforced: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/firewall`,
    method: "GET",
  },
  set_firewall_enforced: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/app-security/firewall`,
    method: "PUT",
  },
  download_saws: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/download-link`,
    method: "GET",
  },
  get_sp_metadata_url: {
    url: `${REACT_APP_API_DOMAIN}/admin/configuration`,
    method: "GET",
  },
  upload_metadata_file: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/sso/upload-file`,
    method: "POST",
  },
  post_login: {
    method: "GET",
    url: `${REACT_APP_API_DOMAIN}/post-login/authorization?timezone=url_param`,
  },
  ///can add a flag of glossary-categories here because we only using it for option labels
  get_glossary_categories: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-categories`,
    method: "GET",
  },
  regenerate_saws_key: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/api-key/url_param`,
    method: "PUT",
  },
  enable_source_type: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/download-dependencies`,
    method: "POST",
  },
  add_endorse_action: {
    url: `${REACT_APP_API_DOMAIN}/nodes/social/endorse?node-id=url_param&node-type=url_param`,
    method: "POST",
  },
  add_follow_action: {
    url: `${REACT_APP_API_DOMAIN}/nodes/social/follow?node-id=url_param&node-type=url_param`,
    method: "POST",
  },
  add_share_action: {
    url: `${REACT_APP_API_DOMAIN}/nodes/social/share?node-id=url_param&node-type=url_param&share-level=url_param`,
    method: "POST",
  },

  lineage_share_action: {
    url: `${REACT_APP_API_DOMAIN}/nodes/lineage/share?node-id=url_param`,
    method: "POST",
  },
  get_social_node: {
    url: `${REACT_APP_API_DOMAIN}/nodes/social?node-id=url_param`,
    method: "GET",
  },
  get_scan_result: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/scan-history/url_param`,
    method: "GET",
  },
  upload_file_to_s3: {
    url: "url_param",
    method: "POST",
  },
  get_upload_file_access_token: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-upload`,
    method: "GET",
  },
  get_paginated_glossary_import_data: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-upload/url_param?page-number=url_param&records-per-page=url_param&status=url_param`,
    method: "GET",
  },
  upload_glossary_file_to_temp_db: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-upload`,
    method: "PUT",
  },
  save_glossary_import_data: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-upload/url_param`,
    method: "POST",
  },
  get_glossary_uploaded_file_statuses: {
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-upload/url_param/statuses-count`,
    method: "GET",
  },
  download_saws_config: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/saws/download-link`,
    method: "POST",
  },
  get_home_recomendations: {
    url: `${REACT_APP_API_DOMAIN}/user-feed/recommendations?size=url_param&offset=url_param`,
    method: "GET",
  },
  get_alerts_with_filter: {
    url: `${REACT_APP_API_DOMAIN}/user-feed/alerts?node-type=url_param&rel-type=url_param&size=url_param&offset=url_param&dmn_ids=url_param&src_ids=url_param&q=url_param`,
    method: "GET",
  },
  get_alerts_without_filter: {
    url: `${REACT_APP_API_DOMAIN}/user-feed/alerts`,
    method: "GET",
  },
  verify_account: {
    url: `${REACT_APP_API_DOMAIN}/account/verify?email=url_param&token=url_param`,
    method: "GET",
  },
  register: {
    url: `${REACT_APP_API_DOMAIN}/account/register`,
    method: "POST",
  },
  get_all_glossary_domains: {
    method: "GET",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-domains`,
  },
  add_glossary_domain: {
    method: "POST",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-domains`,
  },
  update_glossary_domain: {
    method: "PUT",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-domains/url_param`,
  },
  delete_glossary_domain: {
    method: "DELETE",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-domains/url_param`,
  },

  ///no need to add flag, becoz it uses all the data send
  get_all_glossary_categories: {
    method: "GET",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-categories`,
  },
  add_glossary_category: {
    method: "POST",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-categories`,
  },
  update_glossary_category: {
    method: "PUT",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-categories/url_param`,
  },
  delete_glossary_category: {
    method: "DELETE",
    url: `${REACT_APP_API_DOMAIN}/admin/glossary-categories/url_param`,
  },
  get_all_terms: {
    method: "GET",
    url: `${REACT_APP_API_DOMAIN}/nodes/terms?node-type=TRM`,
  },
  get_current_node: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/get-current?node_type=url_param&node_id=url_param`,
    method: "GET",
  },
  get_dashboard_current_node: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/current`,
    method: "GET",
  },
  get_node_data: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/data?node_id=url_param`,
    method: "POST",
    parser: getParsedNodeData,
  },
  post_enterprise_search: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes/typeahead?q=url_param`,
    method: "GET",
  },
  get_search_filters_aggregate: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes/aggregate-attribute?q=url_param`,
    method: "GET",
  },
  get_enterprise_search_results: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes?q=url_param`,
    method: "GET",
  },
  get_nodes_count: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes/count?q=url_param`,
    method: "GET",
  },
  test_connection_url: {
    url: `https://url_param:url_param/webservice/testConnection`,
    method: "POST",
  },
  update_source_status: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/status-update`,
    method: "PUT",
  },
  verify_test_drive_token: {
    url: `${REACT_APP_API_DOMAIN}/account/product-tour/auth-code`,
    method: "POST",
  },
  get_source_verifier_code: {
    url: `https://127.0.0.1:8183/webservice/getVerifierCode`,
    method: "POST",
  },
  add_term: {
    url: `${REACT_APP_API_DOMAIN}/nodes/terms?node-type=TRM`,
    method: "POST",
  },
  get_governance_views: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view?node_type=url_param&node_id=url_param`,
    method: "GET",
  },
  create_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view?node_type=url_param&node_id=url_param`,
    method: "POST",
  },
  update_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/url_param?node_type=url_param&node_id=url_param`,
    method: "PUT",
  },
  delete_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/url_param?node_type=url_param`,
    method: "DELETE",
  },
  change_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/url_param/set-current?node_type=url_param&ref_node_type=url_param&node_id=url_param`,
    method: "PUT",
  },
  get_dashboard_governance_views: {
    url: `${REACT_APP_API_DOMAIN}/dashboard`,
    method: "GET",
  },
  create_dashboard_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/dashboard`,
    method: "POST",
  },
  update_dashboard_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param`,
    method: "PUT",
  },
  get_dashboard_governance_view_by_id: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param`,
    method: "GET",
  },
  delete_dashboard_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param`,
    method: "DELETE",
  },
  change_dashboard_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/current`,
    method: "POST",
  },
  get_criteria_fields: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/get-fields?node_type=url_param&module_type=url_param`,
    method: "GET",
  },
  get_criteria_filters: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/get-filters-name?module_type=url_param`,
    method: "GET",
  },
  get_field_data: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/filter-values?field_name=url_param&field_id_field=url_param&view_type=url_param&node_id=url_param`,
    method: "GET",
  },
  get_field_data_with_delimiter: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/gov-view/filter-values?field_name=url_param&field_id_field=url_param&view_type=url_param&delimiter='url_param'&node_id=url_param`,
    method: "GET",
  },
  update_field_data: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/fields-data-update?node_type=url_param&search_id=url_param`,
    method: "PUT",
  },
  get_mass_editable_fields: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/fields-values?node_type=url_param&tags=url_param`,
    method: "GET",
    parser: getParsedMassEditableFields,
  },
  get_mass_data_classification_fields: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/fields-values?node_type=url_param&dcl=url_param`,
    method: "GET",
    parser: getParsedMassDataClassificationFields,
  },
  dynamic_form_url_get: {
    url: "url_param",
    method: "GET",
  },
  dynamic_form_url_post: {
    url: "url_param",
    method: "POST",
  },
  download_governance_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/download?file_name=url_param`,
    method: "POST",
  },
  get_share_list: {
    url: `${REACT_APP_API_DOMAIN}/nodes/social/share?node-id=url_param&node-type=url_param`,
    method: "GET",
  },
  get_recent_search_keywords: {
    url: `${REACT_APP_API_DOMAIN}/search/user-recent-keywords`,
    method: "GET",
    parser: getRecentSearchKeyWords,
  },
  get_recent_activities: {
    url: `${REACT_APP_API_DOMAIN}/user-feed/activities?start-sk=url_param&start-lsi=url_param&is_previous=url_param&manage_access_filter=url_param`,
    method: "GET",
  },
  get_signed_url_to_upload_excel_source_file: {
    url: `${REACT_APP_API_DOMAIN}/catalog/scan/files`,
    method: "POST",
  },
  get_excel_source_folder_files: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/files-listing?key=url_param`,
    method: "GET",
    parser: getParsedExcelSourceFiles,
    blankSlate: excelSourceFilesGridBlankSlate,
  },
  delete_excel_source_file: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/file?key=url_param`,
    method: "DELETE",
  },
  get_excel_source_job_status: {
    url: `${REACT_APP_API_DOMAIN}/catalog/scan/job-status?jobId=url_param`,
    method: "GET",
    parser: getParsedExcelSourceJobStatus,
  },
  check_excel_source_clasify_status: {
    url: `${REACT_APP_API_DOMAIN}/catalog/scan/classify-status`,
    method: "POST",
  },
  download_excel_source_file: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/file?key=url_param`,
    method: "GET",
  },
  get_field_data_999: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/filter-values?col_name=url_param&node_type=url_param&node_id=url_param`,
    method: "POST",
  },
  get_all_tagsets: {
    url: `${REACT_APP_API_DOMAIN}/tag-sets/tags`,
    method: "GET",
  },
  get_tagset: {
    url: `${REACT_APP_API_DOMAIN}/tag-sets/url_param/tags`,
    method: "GET",
  },
  add_tagset: {
    url: `${REACT_APP_API_DOMAIN}/tag-sets/tags`,
    method: "POST",
  },
  update_tagset: {
    url: `${REACT_APP_API_DOMAIN}/tag-sets/url_param/tags`,
    method: "PUT",
  },
  delete_tagset: {
    url: `${REACT_APP_API_DOMAIN}/tag-sets/url_param/tags`,
    method: "DELETE",
  },
  get_analytics_stats: {
    url: `${REACT_APP_API_DOMAIN}/analytics/catalog-inventory`,
    method: "GET",
    parser: getAnalyticsStats,
  },
  get_data_dictionary_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/sources`,
    method: "GET",
    parser: getDataDictionaryAnalytics,
  },
  get_glossary_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/domains`,
    method: "GET",
    parser: getGlossaryAnalytics,
  },
  get_tags_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/tagsets`,
    method: "GET",
    parser: getTagsAnalytics,
  },
  get_users_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/users-data`,
    method: "GET",
    parser: getUsersAnalytics,
  },
  get_single_source_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/sources/summary?src_id=url_param`,
    method: "GET",
    parser: getSingleSourceAnalytics,
  },
  get_single_glossary_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/domains/summary?domain_id=url_param`,
    method: "GET",
    parser: getSingleGlossaryAnalytics,
  },
  get_single_tag_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/tagsets/summary?tagset_id=url_param`,
    method: "GET",
    parser: getSingleTagsetAnalytics,
  },
  get_all_tagset_config: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes/tagsets?node_type=url_param&prominent=url_param`,
    method: "GET",
  },
  get_report_pages: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/worksheets`,
    method: "GET",
  },
  get_report_fields: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/report-fields`,
    method: "GET",
  },
  tableau_test_connection_url: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/on-demand-scan-test-connection`,
    method: "POST",
  },
  on_demand_scan_for_bi_source: {
    url: `${REACT_APP_API_DOMAIN}/catalog/on-demand-scan/`,
    method: "POST",
  },
  get_similar_nodes: {
    url: `${REACT_APP_API_DOMAIN}/nodes/datasets/url_param/related-nodes?node_sub_type=url_param`,
    method: "GET",
  },
  get_lineage_details: {
    url: `${REACT_APP_API_DOMAIN}/nodes/lineage?node-id=url_param&node-type=url_param&direction=url_param&drill-down=url_param`,
    method: "GET",
  },
  authenticate_adls_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/adls/authenticate`,
    method: "POST",
  },
  get_containers_in_adls_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/adls/containers `,
    method: "POST",
  },
  get_folders_in_container_of_adls_source: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/adls/folders `,
    method: "POST",
  },
  on_demand_scan_for_adls_source: {
    url: `${REACT_APP_API_DOMAIN}/catalog/scan/data-lake`,
    method: "POST",
  },
  delete_term: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param?node-type=url_param`,
    method: "DELETE",
  },
  get_data_quality_category_history: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/summary-trend?node-type=url_param&rule-category=url_param`,
    method: "GET",
  },
  get_summary_tiles: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/summary`,
    method: "GET",
  },
  get_details_grid_data: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/rules`,
    method: "GET",
  },
  get_dq_rule_details: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/rules?node-type=url_param`,
    method: "GET",
  },
  get_data_quality_rule_history: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/rule-history/url_param`,
    method: "GET",
  },
  update_dq_rule_action_status: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/rules/url_param/update-status`,
    method: "POST",
  },
  download_dq_rules: {
    url: `${REACT_APP_API_DOMAIN}/data-quality/url_param/rule-export?is-alert=url_param`,
    method: "GET",
  },
  resend_invite_user: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/url_param/re-invite`,
    method: "POST",
  },
  get_analytics_dictionary_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/analytics-catalog`,
    method: "GET",
    parser: getAnalyticsDictionaryAnalytics,
  },
  get_single_analytics_source_analytics: {
    url: `${REACT_APP_API_DOMAIN}/analytics/analytics-catalog/summary?src_id=url_param`,
    method: "GET",
    parser: getSinglAnalyticsSourceAnalytics,
  },
  get_source_workspaces: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/pbi-workspaces`,
    method: "POST",
  },
  get_source_schemas: {
    url: `https://url_param:url_param/webservice/sources/url_param/schemas`,
    method: "POST",
  },
  get_source_databases: {
    url: `https://url_param:url_param/webservice/sources/url_param/databases`,
    method: "POST",
  },
  get_tableau_source_folders: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/tbu-folders`,
    method: "POST",
  },
  get_all_global_filters: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=url_param&is_finegrain_enabled=url_param`,
    method: "GET",
  },
  update_global_filters: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/filter-settings`,
    method: "POST",
  },
  get_selected_global_filters: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/filter-settings`,
    method: "GET",
  },
  get_data_import_config: {
    url: `${REACT_APP_API_DOMAIN}/data-import/templates`,
    method: "GET",
  },
  get_data_import_fields_config: {
    url: `${REACT_APP_API_DOMAIN}/data-import/templates/url_param`,
    method: "GET",
  },
  get_data_import_upload_signed_url: {
    url: `${REACT_APP_API_DOMAIN}/data-import/templates/url_param/upload-signed-url`,
    method: "GET",
  },
  get_data_import_data_and_count: {
    url: `${REACT_APP_API_DOMAIN}/data-import/import-sample?file-path=url_param`,
    method: "GET",
  },
  get_signed_url_of_data_import_template: {
    url: `${REACT_APP_API_DOMAIN}/data-import/templates/url_param/signed-url`,
    method: "GET",
  },
  start_fields_import: {
    url: `${REACT_APP_API_DOMAIN}/data-import/start-import`,
    method: "POST",
  },
  get_tutorial_steps: {
    url: `https://api.airtable.com/v0/url_param/url_param?filterByFormula=url_param`,
    method: "GET",
  },
  get_dvsum_search_results: {
    url:
      "https://api.airtable.com/v0/url_param/url_param?filterByFormula=url_param",
    method: "GET",
  },
  create_feedback_req: {
    url: `https://api.airtable.com/v0/url_param/url_param`,
    method: "POST",
  },
  get_all_chat_bots: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis?node-id=url_param`,
    method: "GET",
  },
  delete_analysis: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param?node-type=url_param`,
    method: "DELETE",
  },
  get_query_block_results: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/queries/url_param/results`,
    method: "POST",
  },
  get_datasets: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/source/tables`,
    method: "POST",
  },
  get_related_datasets: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/related-tables`,
    method: "POST",
  },
  search_table: {
    url: `${REACT_APP_API_DOMAIN}/search/nodes/url_param?search-text=url_param&node-type=url_param`,
    method: "GET",
  },
  start_execution_for_query: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/queries/url_param/execute`,
    method: "POST",
  },
  get_prompt: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/prompt`,
    method: "GET",
  },
  get_prompt_sumary: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/topic-info`,
    method: "GET",
  },
  create_prompt: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/prompt`,
    method: "POST",
  },
  update_prompt: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/prompt?is_reset=url_param&pinned_tables_ids=url_param`,
    method: "PUT",
  },
  get_loggedin_user_domain_and_subdomain: {
    url: `${REACT_APP_API_DOMAIN}/admin/sub-domains?is_finegrain_enabled=url_param`,
    method: "GET",
  },
  get_import_owner_details: {
    url: `${REACT_APP_API_DOMAIN}/data-import/glossary-access`,
    method: "GET",
  },
  get_chat_listing: {
    url: `${REACT_APP_API_DOMAIN}/chats`,
    method: "GET",
  },
  get_analysis_topics_list: {
    url: `${REACT_APP_API_DOMAIN}/chats/topics`,
    method: "GET",
  },
  get_workflow_template: {
    url: `${REACT_APP_API_DOMAIN}/workflow/template?node_type=url_param`,
    method: "GET",
    parser: getWorkflowTemplates,
  },
  get_workflow_steps: {
    url: `${REACT_APP_API_DOMAIN}/workflow/None/steps?node_type=url_param&node_id=url_param`,
    method: "GET",
    parser: getWorkflowSteps,
  },
  workflow_action: {
    url: `${REACT_APP_API_DOMAIN}/workflow/url_param/action?in_action=url_param&node_id=url_param&node_type=url_param`,
    method: "PUT",
  },
  review_term_changes: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/review?node_type=url_param`,
    method: "GET",
    parser: getTermChangesForReview,
  },
  user_activities: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/user-activities?node_type=url_param&num_records=url_param`,
    method: "GET",
    parser: getUserActivities,
  },

  get_guide_me_questions: {
    url: `${REACT_APP_API_DOMAIN}/chats/url_param/guide-me?chat_id=url_param`,
    method: "POST",
  },
  get_pinned_tbls_manage_fields: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/pinned-tables/fields?pinned_tables=url_param`,
    method: "GET",
    parser: parseFieldsOfPinnedTables,
  },
  get_nodes_list: {
    url: `${REACT_APP_API_DOMAIN}/nodes/search?node-type=url_param&enable-count=url_param&module-type=url_param`,
    method: "GET",
  },
  get_tables_list: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/child?node-type=url_param&schema_name=url_param`,
    method: "GET",
  },
  get_module_settings: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/module-settings?module-type=url_param`,
    method: "GET",
    parser: getParsedModuleSettings,
  },
  save_module_settings: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/module-settings?module-type=url_param`,
    method: "POST",
  },
  get_user_role_enabled_objects: {
    url: `${REACT_APP_API_DOMAIN}/admin/roles/url_param/enable?module-type=url_param`,
    method: "GET",
    parser: getParsedEnabledObjects,
  },
  get_module_settings_enabled_objects: {
    url: `${REACT_APP_API_DOMAIN}/admin/account/module-settings/enable?module-type=url_param`,
    method: "GET",
    parser: getParsedEnabledObjects,
  },
  get_sub_domains_list: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/child?node-type=url_param`,
    method: "GET",
  },
  get_salesforce_getVerifier_code: {
    url: `https://url_param:url_param/webservice/getVerifierCode`,
    method: "POST",
  },
  authenticate_salesforce_Verifier_code: {
    url: `https://url_param:url_param/webservice/authenticateVerifierCode`,
    method: "POST",
  },
  get_erd_edge_info: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/tables-relationship/url_param`,
    method: "GET",
    parser: getErdEdgeInfo,
  },
  delete_erd_edge: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/tables-relationship?level=url_param`,
    method: "PUT",
  },
  confirm_erd_edge: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/tables-relationship?level=url_param`,
    method: "PUT",
  },
  save_erd_edge: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/tables-relationship?level=url_param`,
    method: "PUT",
  },
  get_reference_table: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/source-tables`,
    method: "GET",
  },
  get_data_model_max_level: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/max-edr-level`,
    method: "GET",
  },
  get_data_model_with_levels: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model?level=url_param`,
    method: "GET",
  },
  get_relationship_columns_data: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?erd_tbls=url_param`,
    method: "GET",
    parser: getParsedRelationshipColumnsData,
  },
  get_source_columns_data: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?erd_src=url_param`,
    method: "GET",
  },
  save_salesforce_authsettings: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/salesforce/saveAuthSettings`,
    method: "POST",
  },
  get_source_gateway_info: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/url_param/saws-info?id=url_param&type=url_param`,
    method: "GET",
  },
  ping_check: {
    url: `https://url_param:url_param/webservice/pingCheck?q=url_param`,
    method: "POST",
  },
  get_data_model_state: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/erd-state`,
    method: "GET",
  },
  save_data_model_state: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/data-model/erd-state`,
    method: "PUT",
  },
  get_user_preferences: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/preferences`,
    method: "GET",
  },
  update_user_preferences: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/preferences`,
    method: "POST",
  },
  cancel_scan: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/scan-history/url_param/cancel?delete_all_schedules=url_param`,
    method: "POST",
  },
  get_cloud_watch_logs: {
    url: `${REACT_APP_API_DOMAIN}/cloudwatch/logs?start_time=url_param&end_time=url_param&id=url_param&level=url_param&next_token=url_param&max_limit=url_param`,
    method: "GET",
  },
  get_recommended_rules: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/recommended?tbl-id=url_param`,
    method: "GET",
  },

  get_node_edit_config: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/filters?node-type=url_param&filter-type=url_param`,
    method: "GET",
  },

  save_node_edit_config: {
    url: `${REACT_APP_API_DOMAIN}/nodes/url_param/filters?node-type=url_param&filter-type=url_param&filters-only=url_param`,
    method: "POST",
  },

  get_column_config: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?isRef=url_param`,
    method: "GET",
  },

  save_column_order: {
    url: `${REACT_APP_API_DOMAIN}/nodes/tables/url_param/columns?isRef=url_param`,
    method: "PUT",
  },

  add_rules: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules`,
    method: "POST",
  },
  update_rules: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/url_param`,
    method: "PUT",
  },

  run_profiling: {
    url: `${REACT_APP_API_DOMAIN}/jobs?job-category=url_param&search-id=url_param`,
    method: "POST",
  },

  cancel_run_profiling: {
    url: `${REACT_APP_API_DOMAIN}/jobs/cancel`,
    method: "POST",
  },

  update_job_scheduler: {
    url: `${REACT_APP_API_DOMAIN}/jobs/url_param?job-category=url_param`,
    method: "PUT",
  },

  update_job_execution_status: {
    url: `${REACT_APP_API_DOMAIN}/jobs/url_param/set-status?job-category=url_param`,
    method: "PATCH",
  },

  get_schedulers: {
    url: `${REACT_APP_API_DOMAIN}/jobs?job-category=url_param`,
    method: "POST",
  },

  get_node_from_view: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/filter-data?node_type=url_param`,
    method: "POST",
  },

  get_rules_info_from_zendesk_article: {
    url: `https://dvsum.zendesk.com/api/v2/help_center/en-us/articles/230178488.json`,
    method: "GET",
  },

  get_rule_execution_history: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/url_param/execution-history?interval_in_days=url_param`,
    method: "GET",
  },

  get_job_execution_history: {
    url: `${REACT_APP_API_DOMAIN}/jobs/url_param/executions?page_size=url_param&page_number=url_param&filter=url_param&start_date=url_param&end_date=url_param`,
    method: "GET",
  },

  get_job_execution_summary: {
    url: `${REACT_APP_API_DOMAIN}/jobs/url_param/executions/url_param/summary`,
    method: "GET",
  },

  clone_rule: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/clone`,
    method: "POST",
  },

  rule_actions: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/url_param/action`,
    method: "POST",
  },

  get_rule_specific_col_seq: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/url_param/column-sequence?table-id=url_param&seq-type=url_param&col-ids=url_param`,
    method: "GET",
  },
  update_col_seq: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/url_param/column-sequence`,
    method: "POST",
  },
  user_onboarding_info: {
    url: `${REACT_APP_API_DOMAIN}/admin/users/user-onboarding-info`,
    method: "POST",
  },
  get_data_analysis_upload_signed_url: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/upload-signed-url`,
    method: "GET",
  },
  get_data_analysis_uploaded_file_signed_url: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/uploaded-file-signed-url?file_name=url_param`,
    method: "GET",
  },
  save_websocket_connections: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/websocket-connections`,
    method: "POST",
  },
  run_job: {
    url: `${REACT_APP_API_DOMAIN}/jobs/url_param/run-now`,
    method: "POST",
  },
  get_presigned_url: {
    url: `${REACT_APP_API_DOMAIN}/s3/presigned-url?file_path=url_param&is_gzip=true`,
    method: "GET",
  },
  get_presigned_post_url: {
    url: `${REACT_APP_API_DOMAIN}/s3/presigned-url/upload?core_folder=url_param&sub_folder=url_param`,
    method: "POST",
  },
  delete_pattern: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/patterns/url_param`,
    method: "DELETE",
  },
  update_pattern: {
    url: `${REACT_APP_API_DOMAIN}/dq-rules/patterns/url_param`,
    method: "PUT",
  },
  get_bot_concepts: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/concepts?da_id=url_param&src_id=url_param`,
    method: "GET",
  },
  save_bot_concepts: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/concepts?da_id=url_param&src_id=url_param`,
    method: "POST",
  },
  update_bot_concepts: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/concepts?da_id=url_param&src_id=url_param`,
    method: "PUT",
  },
  delete_bot_concepts: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/concepts?da_id=url_param&src_id=url_param`,
    method: "DELETE",
  },
  get_analysis_queries: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/da-queries?da_id=url_param`,
    method: "GET",
  },
  save_analysis_queries: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/da-queries`,
    method: "POST",
  },
  update_analysis_queries: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/da-queries`,
    method: "PUT",
  },
  delete_analysis_queries: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/da-queries`,
    method: "DELETE",
  },
  get_bot_engagements: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/trends?node_id=url_param&timezone=url_param`,
    method: "POST",
    parser: getParsedBotEngagements,
  },
  get_bot_top_users: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/top-users?node_id=url_param&timezone=url_param`,
    method: "POST",
    parser: getParsedBotTopUsers,
  },
  clone_analysis_node: {
    url: `${REACT_APP_API_DOMAIN}/data-analysis/url_param/clone?bot_id=url_param`,
    method: "POST",
  },
  get_all_reference_dictionaries: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries`,
    method: "GET",
  },
  get_reference_dictionary: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries/url_param`,
    method: "GET",
  },
  get_reference_dictionary_values: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries/url_param/values`,
    method: "GET",
  },
  create_reference_dictionary_values: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries/url_param/values`,
    method: "POST",
  },
  update_reference_dictionary_values: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries/url_param/values`,
    method: "PUT",
  },
  create_reference_dictionary: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries`,
    method: "POST",
  },
  update_reference_dictionary: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries/url_param`,
    method: "PUT",
  },
  delete_reference_dictionary: {
    url: `${REACT_APP_API_DOMAIN}/ref-dictionaries`,
    method: "DELETE",
  },
  get_review_caddi_feedbacks_list: {
    url: `${REACT_APP_API_DOMAIN}/node-listing/listing/url_param/feedback-detail?node_id=url_param`,
    method: "POST",
    parser: getParsedReviewCaddiFeedbacksList,
  },
  get_dashboard_widgets: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets`,
    method: "GET",
  },
  get_dashboard_home_widgets: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/pinned-widgets`,
    method: "GET",
  },
  get_widget_details: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets/url_param/data?time_zone=url_param`,
    method: "GET",
  },
  add_widget: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets`,
    method: "POST",
  },
  update_widget: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets/url_param`,
    method: "PUT",
  },
  get_dashboard_filters: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/filters?view_type=url_param&widget_id=url_param`,
    method: "GET",
  },
  download_widget_data: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets/url_param/download`,
    method: "GET",
  },
  add_widget_home: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets/url_param/pin?action=url_param`,
    method: "POST",
  },
  delete_widget: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/url_param/widgets/url_param`,
    method: "DELETE",
  },
  reorder_widget_on_home: {
    url: `${REACT_APP_API_DOMAIN}/dashboard/pinned-widgets/order`,
    method: "POST",
  },
  data_source_authenticate: {
    url: `${REACT_APP_API_DOMAIN}/data-sources/sources/authenticate`,
    method: "POST",
  },
};
