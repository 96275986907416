import { AxiosResponse } from "axios";

import {
  ReferenceDictionariesParserReturnType,
  ReferenceDictionariesResponseType,
  ReferenceDictionariesValuesParserReturnType,
  ValuesSectionGridDataType,
} from "./referencedictionaryparser.types";
import {
  getObjectEntries,
  getObjectKeys,
  getObjectValues,
  jsonParse,
} from "../../utils";

export function getParsedRefDicts({
  data,
}: AxiosResponse<
  ReferenceDictionariesResponseType[]
>): ReferenceDictionariesParserReturnType[] {
  return (
    data?.map((item) => ({
      id: item?.DICT_ID,
      name: item?.DICT_NAME || "",
      description: item?.DICT_DESC || "",
      isHierarchical: item?.IS_HIERARCHICAL,
      hierarchicalLevel: item?.DICT_HIER_LEVEL,
      mappingConfig: item?.DICT_MAPPING_CONFIG || "",

      createdBy: item?.CREATED_BY || "",
      createdOn: item?.CREATED_ON || "",
      updatedBy: item?.UPDATED_BY || "",
      updatedOn: item?.UPDATED_ON || "",
      isKeyValuePair: item?.IS_KEY_VALUE_PAIR,
    })) || []
  );
}

export function getParsedRefDict({
  data,
}: AxiosResponse<
  ReferenceDictionariesResponseType[]
>): ReferenceDictionariesParserReturnType {
  const parsedData = data?.[0] || {};

  return {
    id: parsedData?.DICT_ID,
    name: parsedData?.DICT_NAME || "",
    description: parsedData?.DICT_DESC || "",
    isHierarchical: parsedData?.IS_HIERARCHICAL || false,
    hierarchicalLevel: parsedData?.IS_HIERARCHICAL
      ? parsedData?.DICT_HIER_LEVEL || 1
      : undefined,
    mappingConfig: jsonParse(parsedData?.DICT_MAPPING_CONFIG) || {},

    createdBy: parsedData?.CREATED_BY || "",
    createdOn: parsedData?.CREATED_ON || "",
    updatedBy: parsedData?.UPDATED_BY || "",
    updatedOn: parsedData?.UPDATED_ON || "",
    isKeyValuePair: parsedData?.IS_KEY_VALUE_PAIR,
    dataHeaders: jsonParse(parsedData?.DICT_MAPPING_CONFIG)?.data_headers,
  };
}

const addDoubleQuotesIfCommaExistsInValue = (
  data: ValuesSectionGridDataType
): Array<{ [key: string]: string | number }> => {
  return data?.map((item) => {
    if (typeof item === "object") {
      const objEntries = getObjectEntries(item);
      return objEntries?.reduce((acc, [key, value]) => {
        const transformedKey = key?.includes(",") ? `"${key}"` : key;

        const transformedValue =
          typeof value === "string" && value?.includes(",")
            ? `"${value}"`
            : value;

        acc[transformedKey] = transformedValue;
        return acc;
      }, {} as { [key: string]: string | number });
    }
    return item;
  });
};

export function getParsedRefDictValues({
  data,
}: AxiosResponse<ValuesSectionGridDataType>): ReferenceDictionariesValuesParserReturnType {
  const doubleQoutedValues = addDoubleQuotesIfCommaExistsInValue(data);

  const parsedData = doubleQoutedValues
    ?.map((rowData, index) => {
      const objectValues = getObjectValues(rowData);
      const objectKeys = getObjectKeys(rowData);

      //find better way to do this
      if (index === 0) {
        if (
          objectKeys?.includes("Column_CD_1") ||
          objectKeys?.includes("Column_Value_1") ||
          objectKeys?.includes("Key") ||
          objectKeys?.includes("Value") ||
          objectKeys?.includes("Hierarchy_Code_1") ||
          objectKeys?.includes(" Hierarchy_Value_1")
        )
          return `${objectValues}\n`;

        return `${objectKeys}\n${objectValues}\n`;
      }

      return `${objectValues}\n`;
    })
    .join("");

  return { gridData: data, content: parsedData };
}
